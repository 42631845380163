module.exports = [{
      plugin: require('../../../../../../../../../../../output/src716026708/src/github.com/pnwcode/pnwcode/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../../../../../../../../output/src716026708/src/github.com/pnwcode/pnwcode/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-M2HBBWF","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"dataLayerName":"dataLayer","gtmAuth":"k97rK8sQwSU3JkK_GEgzYg","gtmPreview":"env-1"},
    },{
      plugin: require('../../../../../../../../../../../output/src716026708/src/github.com/pnwcode/pnwcode/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"initcommit","short_name":"initcommit","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"browser","icon":"src/images/icon.svg"},
    }]
